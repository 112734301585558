export default {
  data() {
    return {
      validate: {
        editedCba: {
          cbaItemName: { isValid: true, errMsg: "" },
        },
        jwnetItemId: { isValid: true, errMsg: "" },
        cbaItemList: [
          {
            cbaItemName: { isValid: true, errMsg: "" },
          },
        ],
      },
      isValidForm: true,
    };
  },
  methods: {
    check() {
      let isValidForm = true;
      const v = this.validate;
      if (!v.jwnetItemId.isValid) {
        v.jwnetItemId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      v.cbaItemList.forEach((cba) => {
        if (!cba.cbaItemName.isValid) {
          cba.cbaItemName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      });
      return isValidForm;
    },
    checkEdit() {
      let isValidForm = true;
      const v = this.validate;
      if (!v.editedCba.cbaItemName.isValid) {
        v.editedCba.cbaItemName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      return isValidForm;
    },
    runValidate() {
      let isValidForm = false;
      isValidForm = this.check();

      return isValidForm;
    },
  },
};
