export default {
  data() {
    return {
      form: {
        jwnetItemId: "",
        cbaItemList: [
          {
            cbaItemName: "",
            remarks: "",
          },
        ],
      },
      editForm: {
        cbaItemName: "",
        remarks: "",
        isActive: false
      },
    };
  },
};
