import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      jwnetItems: [],
      searchItemList: [],
      cbaitem: {
        jwnetItemInfo: {
          id: 0,
          name: ""
        },
        cbaItemInfo: {
          id: 0,
          name: "",
          remarks: ""
        },
        isActive: true
      },
    };
  },

  methods: {
    // 廃棄物種類選択肢取得APi
    getJwnetItemTypeApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItems = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCbaItemsApi(cbaitemId) {
      return axios
        .get(`${API_URL.WASTEITEM.CBAITEM}/${cbaitemId}`)
        .then((res) => {
          this.cbaitem = res.data;
          this.editForm.cbaItemName = this.cbaitem.cbaItemInfo.name;
          this.editForm.remarks = this.cbaitem.cbaItemInfo.remarks;
          this.editForm.isActive = this.cbaitem.isActive;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 品目検索API 検索結果
    getItemSearchApi() {
      this.searchItemList = [];

      // searchParamsを含めてgetする
      let url = API_URL.WASTEITEM.CBAITEM;
      if (this.searchParams.item) {
        url += `?jwnetItemTypeId=${this.searchParams.item}`;
      }
      if (this.searchParams.keyword) {
        url += url.indexOf('?') >= 0 ? `&cbaItemName=${this.searchParams.keyword}` : `?cbaItemName=${this.searchParams.keyword}`;
      }

      return axios
        .get(url)
        .then((res) => {
          this.searchItemList = res.data.jwnetItemCBAItemList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
